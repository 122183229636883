@use 'colors';
@use 'fonts';
@use 'sizes';
@use 'sass:map' as map;


.mdc-button {
  --mdc-text-button-label-text-color: #{colors.$color-white};
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, .5);
  --mat-text-button-state-layer-color: #{colors.$color-white};
  --mat-text-button-ripple-color: rgba(255, 255, 255, .1);

  --mat-text-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-text-button-container-height: 38px;
  --mdc-text-button-label-text-size: #{sizes.$size-4};
  --mdc-text-button-container-shape: 6px;
  --mdc-text-button-label-text-font: #{fonts.$font-family-primary};
  --mdc-text-button-label-text-weight: 500;

  --mat-filled-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-filled-button-container-height: 38px;
  --mdc-filled-button-label-text-size: #{sizes.$size-4};
  --mdc-filled-button-container-shape: 6px;
  --mdc-filled-button-label-text-font: #{fonts.$font-family-primary};
  --mdc-filled-button-label-text-weight: 500;

  --mat-unelevated-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-unelevated-button-container-height: 38px;
  --mdc-unelevated-button-label-text-size: #{sizes.$size-4};
  --mdc-unelevated-button-container-shape: 6px;
  --mdc-unelevated-button-label-text-font: #{fonts.$font-family-primary};
  --mdc-unelevated-button-label-text-weight: 500;

  --mat-outlined-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-outlined-button-container-height: 38px;
  --mdc-outlined-button-label-text-size: #{sizes.$size-4};
  --mdc-outlined-button-container-shape: 6px;
  --mdc-outlined-button-label-text-font: #{fonts.$font-family-primary};
  --mdc-outlined-button-label-text-weight: 500;

  &.mat-mdc-button-base {
    min-width: unset;
  }

  .mdc-button__label {
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}

.mat-mdc-button-base {
  --mdc-text-button-label-text-color: #{colors.$text-color-default};
  --mdc-text-button-outline-color: #{colors.$button-border-color-gray};

  --mdc-text-button-disabled-label-text-color: #{colors.$button-basic-disabled-label-text};
  --mdc-text-button-disabled-container-color: #{map.get(colors.$primary-palette, 80)};

  &.loyalty {
    --mdc-text-button-disabled-container-color: #{map.get(colors.$loyalty-primary-palette, 80)};
  }

  &:hover {
    outline: 1px solid colors.$button-border-color-gray;
  }

  &:disabled {
    background: map.get(colors.$neutral-palette, 98);
    border: 1px solid map.get(colors.$neutral-palette, 95);
  }
}

.mat-mdc-button-base .mdc-button__label {
  --mdc-filled-button-label-text-size: #{sizes.$size-3-5};
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-label-text-color: #{map.get(colors.$neutral-palette, 98)};
  --mdc-filled-button-container-color: #{map.get(colors.$primary-palette, 35)};

  --mdc-filled-button-disabled-label-text-color: #{map.get(colors.$primary-palette, 50)};
  --mdc-filled-button-disabled-container-color: #{map.get(colors.$primary-palette, 80)};

  &.loyalty {
    --mdc-filled-button-container-color: #{map.get(colors.$loyalty-primary-palette, 35)};
    --mdc-filled-button-disabled-label-text-color: #{map.get(colors.$loyalty-primary-palette, 50)};
    --mdc-filled-button-disabled-container-color: #{map.get(colors.$loyalty-primary-palette, 80)};
  }

  &.mdc-button {
    border: 1px solid map.get(colors.$primary-palette, 25);

    &.loyalty {
      border: 1px solid map.get(colors.$loyalty-primary-palette, 25);
    }
  }

  &:hover {
    --mdc-filled-button-container-color: #{map.get(colors.$primary-palette, 25)};

    &.loyalty {
      --mdc-filled-button-container-color: #{map.get(colors.$loyalty-primary-palette, 25)};
    }
  }

  &:disabled {
    &.mat-mdc-button-disabled {
      border: 1px solid map.get(colors.$primary-palette, 60);

      &.loyalty {
        border: 1px solid map.get(colors.$loyalty-primary-palette, 60);
      }
    }
  }

  &.button-small {
    &:disabled {
      &.mat-mdc-button-disabled {
        border: 1px solid colors.$button-primary-small-bgr;
      }
    }
  }
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-label-text-color: #{colors.$text-color-default};
  --mdc-outlined-button-outline-color: #{map.get(colors.$neutral-palette, 95)};

  --mdc-outlined-button-disabled-label-text-color: #{map.get(colors.$neutral-palette, 80)};
  --mdc-outlined-button-disabled-outline-color: #{map.get(colors.$neutral-palette, 95)};

  /* hover/focus/active opacity */
  --mat-outlined-button-hover-state-layer-opacity: 0;
  --mat-outlined-button-focus-state-layer-opacity: 0;
  --mat-outlined-button-pressed-state-layer-opacity: 0;

  background: map.get(colors.$neutral-palette, 98) !important;

  &.button-small {
    &:hover {
      background: map.get(colors.$neutral-palette, 95) !important;
    }

    --mdc-outlined-button-disabled-label-text-color: #{colors.$button-basic-disabled-label-text};

    --mdc-outlined-button-disabled-outline-color: #{colors.$button-basic-disabled-label-text};

    background: map.get(colors.$neutral-palette, 98) !important;
  }
}

.mat-mdc-fab {
  --mdc-fab-container-color: #292726;
  --mdc-fab-container-shape: 5.5px;
  --mat-fab-foreground-color: #DDDBDD;
  --mdc-fab-container-elevation-shadow: 0px 16px 40px 0px #00000038;

  min-width: 54px !important;
  width: auto !important;
  padding: 0 8px !important;
  border: 1px solid map.get(colors.$primary-palette, 70) !important;
  font: fonts.$button-text;
  letter-spacing: 0.15em;

  &.loyalty {
    border: 1px solid map.get(colors.$loyalty-primary-palette, 70) !important;
  }

  .mat-icon {
    min-height: 30px;
    min-width: 30px;
    height: 30px;
    width: 30px;
  }
}

.button-small {
  --mdc-filled-button-container-height: 32px;
  --mat-filled-button-horizontal-padding: #{sizes.$size-3};
  --mdc-filled-button-label-text-size: #{sizes.$size-3-5};

  --mdc-outlined-button-container-height: 32px;
  --mat-outlined-button-horizontal-padding: #{sizes.$size-3};
  --mdc-outlined-button-label-text-size: #{sizes.$size-3-5};

  --mdc-unelevated-button-container-height: 32px;
  --mat-unelevated-button-horizontal-padding: #{sizes.$size-3};
  --mdc-unelevated-button-label-text-size: #{sizes.$size-3-5};

  --mdc-text-button-container-height: 32px;
  --mat-text-button-horizontal-padding: #{sizes.$size-3};
  --mdc-text-button-label-text-size: #{sizes.$size-3-5};

  &.mdc-button .mat-mdc-button-touch-target {
    height: 32px;
  }
}

.button-medium {
  --mdc-filled-button-container-height: 38px;
  --mat-filled-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-filled-button-label-text-size: #{sizes.$size-3-5};

  --mdc-outlined-button-container-height: 38px;
  --mat-outlined-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-outlined-button-label-text-size: #{sizes.$size-3-5};

  --mdc-unelevated-button-container-height: 38px;
  --mat-unelevated-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-unelevated-button-label-text-size: #{sizes.$size-3-5};

  --mdc-text-button-container-height: 38px;
  --mat-text-button-horizontal-padding: #{sizes.$size-3-5};
  --mdc-text-button-label-text-size: #{sizes.$size-3-5};

  &.mdc-button .mat-mdc-button-touch-target {
    height: 38px;
  }
}
