/* You can add global styles to this file, and also import other style files */
@use 'styles/colors';
@use 'styles/fonts';
@use 'styles/sizes';
@use 'styles/flex';

@use 'styles/buttons';
@use 'styles/mat-themes';
@use 'styles/forms';

@use '@angular/cdk/overlay-prebuilt';

html {
  font: fonts.$body-base;

  body {
    min-height: 100vh;
    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
    padding: 0;
    margin: 0;
    overscroll-behavior-y: contain;
  }

  --mat-autocomplete-background-color: #FFFFFF;
  ///* default/primary color */
  --mat-option-selected-state-label-text-color: #000000;
  --mat-option-label-text-color: #000000;
  --mat-datepicker-calendar-container-background-color: #FFFFFF;

  --mat-paginator-container-background-color: #FFFFFF;
}

a {
  text-decoration: none;
}

.input-autocomplete {
  cursor: pointer;
  width: 100%;

  .mat-mdc-form-field-infix {
    .mdc-text-field__input {
      width: calc(100% - 24px) !important;
    }

    .mat-mdc-select-arrow-wrapper {
      width: fit-content;
      float: right;

      svg {
        fill: white;
      }
    }
  }

  margin-bottom: -1.25em // Remove bottom padding added for mat-form-field
}

.custom-chip {
  @include flex.row(center, center);
  height: 27px;
  border-radius: 5px;
  background: map-get(colors.$primary-palette, 80);
  font: fonts.$create-action-title;
  text-align: left;
  padding-left: 3px;
  padding-right: 3px;
  color: map-get(colors.$primary-palette, 20);

  mat-icon {
    height: 16px;
    width: 16px;
    fill: map-get(colors.$primary-palette, 35);
    @include flex.row(start, center);
  }
}

.marker-border {
  border: 2px solid blue;
}


.free-money-map-icon-container {
  img {
    &.selected {
      border: 4px solid #2156F4;
    }
  }
  .reward-chip {
    background-color: #2156F4;
  }
}

.loyalty-level-devoted-map-icon-container {
  img {
    &.selected {
      border: 4px solid colors.$map-color-loyalty-level-devoted;
    }
  }
  .reward-chip {
    background-color: colors.$map-color-loyalty-level-devoted;
  }
}

.loyalty-level-loyal-map-icon-container {
  img {
    &.selected {
      border: 4px solid colors.$map-color-loyalty-level-loyal;
    }
  }
  .reward-chip {
    background-color: colors.$map-color-loyalty-level-loyal;
  }
}

.loyalty-level-frequent-map-icon-container {
  img {
    &.selected {
      border: 4px solid colors.$map-color-loyalty-level-frequent;
    }
  }
  .reward-chip {
    background-color: colors.$map-color-loyalty-level-frequent;
  }
}

.loyalty-level-casual-map-icon-container {
  img {
    &.selected {
      border: 4px solid colors.$map-color-loyalty-level-casual;
    }
  }
  .reward-chip {
    background-color: colors.$map-color-loyalty-level-casual;
  }
}

.loyalty-level-stranger-map-icon-container {
  img {
    &.selected {
      border: 4px solid colors.$map-color-loyalty-level-stranger;
    }
  }
  .reward-chip {
    background-color: colors.$map-color-loyalty-level-stranger;
  }
}

.map-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;

  img {
    width: 100%;
    border-radius: 8px;
    background-color: white;
    border: 4px solid #FFFFFF;
  }

  .reward-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 34px;
    height: 24px;
    top: -12px;
    left: 29px;
    gap: 10px;
    border-radius: 40px;
    color: #FFFFFF;
    font: 700 13px/13px Inter;
    font-family: 'Inter', sans-serif;
  }
}

.map-point-container {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #2156F4;
}

.google-map-card {
  div {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-color: #ffffff;

  &.error-snack-bar {
    --mdc-snackbar-container-color: #f23a2f;
  }

  &.success-snack-bar {
    --mdc-snackbar-container-color: #43a446;
  }
}

.w-100 {
  width: 100%;
}
