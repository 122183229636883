$font-family-primary: "Inter", sans-serif;

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('/projects/shared-lib/assets/fonts/Inter_18pt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$font-family-roboto: "Roboto", serif;
@font-face {
  font-family: 'Inter', sans-serif;
  src: url('/projects/shared-lib/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$font-family-ibm-plex: "IBMPlexSans", sans-serif;
@font-face {
  font-family: 'Inter', sans-serif;
  src: url('/projects/shared-lib/assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$title-hero: 700 72px/86.4px $font-family-primary;
$title-page: 700 48px/57.6px $font-family-primary;
$subtitle: 400 32px/38.4px $font-family-primary;
$heading: 600 24px/28.8px $font-family-primary;
$subheading-base: 400 20px/24px $font-family-primary;
$subheading: 500 18px/21.6px $font-family-primary;
$body-base: 400 16px/22.4px $font-family-primary;
$body-small: 400 14px/19.6px $font-family-primary;

$button-text: 500 14px/14px $font-family-primary;
$action-button-text: 500 14px/16px $font-family-primary;
$single-line-body-base: 400 16px/16px $font-family-primary;

$create-action-title: 500 18px/27px $font-family-primary;
$map-marker-card-title: 600 18px/27px $font-family-primary;

$sidebar-celebrity-name: 500 14px/21px $font-family-primary;
$sidebar-menu-item: 500 16px/24px $font-family-primary;
$sidebar-menu-item-bold: 600 16px/24px $font-family-primary;

$action-card-action-id: 500 13px/19.5px $font-family-primary;
$action-card-action-state: 600 12px/18px $font-family-primary;
$action-card-statistics-text: 500 24px/36px $font-family-primary;

$constructed-sentence-text: 500 20px/30px $font-family-primary;

$warning-body-small: 500 14px/19.6px $font-family-primary;

$onboarding-title: 500 32px/44.8px $font-family-primary;
$onboarding-text: 500 20px/28px $font-family-primary;
$onboarding-text-big: 500 20px/32px $font-family-primary;

$card-sentence-text: 400 28px/33.6px $font-family-primary;
$card-sentence-chip: 500 20px/24px $font-family-primary;

//mobile app
$navigation-item: 500 12px/14px $font-family-ibm-plex;
$navigation-item-selected: 700 13px/16px $font-family-ibm-plex;
$features-item-title: 600 16px/20px $font-family-ibm-plex;
$features-item-subtitle: 400 12px/9px $font-family-ibm-plex;
$features-title: 500 18px/23.4px $font-family-ibm-plex;
$features-edit: 500 14px/18px $font-family-ibm-plex;
$mobile-title: 700 18px/21px $font-family-ibm-plex;
$mobile-subtitle: 600 32px/38.4px $font-family-ibm-plex;
$mobile-action-constrains: 700 14px/14px $font-family-primary;
$mobile-body-base-bold: 600 16px/22.4px $font-family-primary;
$mobile-body-bold-labels: 700 14px/21px $font-family-primary;
$mobile-subtitle-normal: 500 16px/22.4px $font-family-primary;
$mobile-body-small-bold: 600 14px/19.6px $font-family-primary;
$mobile-body-chip: 600 14px/21px $font-family-primary;
$mobile-body-big-chip: 600 16px/19.2px $font-family-primary;
$mobile-stats-label: 400 12px/14.4px $font-family-primary;
