@use 'colors';
@use 'fonts';
@use 'flex';
@use 'sizes';
@use 'shadows';

.mdc-form-field .mdc-label {
  color: colors.$text-color-default !important;
  font: fonts.$body-base !important;
}

.mat-mdc-checkbox {
  --mdc-checkbox-unselected-focus-icon-color: #{colors.$checkbox-border-color-default};
  --mdc-checkbox-unselected-icon-color: #{colors.$checkbox-border-color-default};
  --mdc-checkbox-unselected-hover-icon-color: #{colors.$checkbox-border-color-default};
  --mdc-checkbox-state-layer-size: 20px;

  .mat-internal-form-field {
    align-items: normal;
  }

  .mat-mdc-checkbox-touch-target {
    width: 20px;
    height: 20px;
  }

  .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
    --mdc-checkbox-selected-icon-color: #{colors.$bgr-black-light} !important;
  }

  .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
    --mdc-checkbox-selected-focus-icon-color: #{colors.$color-black};
    --mdc-checkbox-selected-hover-icon-color: #{colors.$color-black};
    --mdc-checkbox-selected-icon-color: #{colors.$color-black};
  }

  .mdc-checkbox__background {
    border-radius: sizes.$border-radius-100;
    padding: 1.7px;
  }

  &.mdc-checkbox--disabled {
    .mdc-checkbox__mixedmark {
      --mdc-checkbox-disabled-selected-checkmark-color: #{colors.$button-basic-disabled-label-text};
    }

    .mdc-checkbox__checkmark {
      --mdc-checkbox-disabled-selected-checkmark-color: #{colors.$button-basic-disabled-label-text};
    }

    .mdc-checkbox__background {
      --mdc-checkbox-disabled-unselected-icon-color: #{colors.$checkbox-disabled-border-color};
      --mdc-checkbox-disabled-selected-icon-color: #{colors.$button-border-color-gray};

      border-color: colors.$checkbox-disabled-border-color !important;
      background-color: colors.$button-border-color-gray;
    }
  }
}

.mat-mdc-radio-button {
  width: 100%;
  height: 100%;
  display: block;

  .mdc-form-field.mat-internal-form-field {
    width: 100%;
    height: 100%;
    align-items: unset;
  }

  .mdc-radio {
    margin: 5px 2px;
  }

  .mdc-label {
    width: 100%;
    cursor: pointer;
    padding: 12px 12px 12px 0;
  }

  .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle {
    border-width: 1px !important;
  }

  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
    background-color: colors.$color-white;
  }

  .mdc-radio__native-control:checked:not(:disabled) + .mdc-radio__background {
    .mdc-radio__outer-circle {
      border-width: 4px !important;
    }

    .mdc-radio__inner-circle {
      display: none !important;
    }
  }

  .mdc-radio__native-control:not(:checked) + .mdc-radio__background {
    .mdc-radio__outer-circle {
      border-width: 1px !important;
    }

    .mdc-radio__inner-circle {
      display: none !important;
    }
  }

  .mat-mdc-radio-button .mdc-radio__native-control {
    width: 36px;
    height: 36px;

    &:not(:checked) + .mdc-radio__background .mdc-radio__inner-circle {
      display: none;
    }
  }

  .mdc-radio__inner-circle {
    border-width: 8px !important;
    transform: scale(0.6) !important;
  }

  &.mat-mdc-radio-checked .mdc-radio__background::before {
    width: 36px;
    height: 36px;
  }

  .mdc-radio .mdc-radio__background::before {
    width: 36px;
    height: 36px;
  }

  .mdc-form-field {
    .mdc-radio {
      width: 16px;
      height: 16px;
    }

    .mat-mdc-radio-touch-target {
      width: 40px;
      height: 40px;
    }

    .mdc-radio__background {
      width: 16px;
      height: 16px;

      --mdc-radio-selected-icon-color: #{map-get(colors.$primary-palette, 40)};
      --mdc-radio-disabled-selected-icon-color: #{colors.$button-basic-disabled-label-text};
      --mdc-radio-disabled-unselected-icon-color: #{colors.$button-basic-disabled-label-text};
      --mdc-radio-disabled-unselected-icon-opacity: 1;
      --mdc-radio-disabled-selected-icon-opacity: 1;
      --mdc-radio-unselected-hover-icon-color: #{map-get(colors.$neutral-palette, 80)};
      --mdc-radio-unselected-icon-color: #{map-get(colors.$neutral-palette, 80)};
      --mdc-radio-unselected-pressed-icon-color: #{map-get(colors.$neutral-palette, 80)};
      --mdc-radio-unselected-focus-icon-color: #{map-get(colors.$neutral-palette, 80)};

      .mdc-radio__outer-circle {
        background-color: colors.$color-white;
      }
    }
  }
}

.mat-mdc-slide-toggle {

  .mdc-switch {
    --mdc-switch-track-width: #{sizes.$size-10};
    --mdc-switch-track-height: #{sizes.$size-6};

    --mdc-switch-selected-track-color: #{colors.$bgr-black-light};
    --mat-switch-track-outline-color: #{colors.$checkbox-border-color-default};

    --mdc-icon-button-icon-size: 18px;
    --mdc-chip-with-avatar-avatar-size: 18px;
    --mat-switch-selected-handle-size: 18px;

    --mdc-switch-unselected-hover-handle-color: #{colors.$checkbox-border-color-default};

    &.mdc-switch--unselected .mdc-switch__handle {
      --mdc-switch-unselected-handle-color: #{colors.$checkbox-border-color-default};
    }

    &.mdc-switch--unselected .mdc-switch__handle:has(.mdc-switch__icons) {
      --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 3px;
    }

    &.mdc-switch--selected .mdc-switch__handle:has(.mdc-switch__icons) {
      --mat-switch-selected-with-icon-handle-horizontal-margin: 0 18px;
    }

    .mdc-switch__handle:has(.mdc-switch__icons) {
      --mat-switch-with-icon-handle-size: 18px;
    }
  }
}

.mat-mdc-form-field {

  &.name {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-input-element.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        font: fonts.$create-action-title;
      }

      &:not(:has(input:focus)) {
        border-color: transparent;
        --mdc-filled-text-field-container-color: transparent;
      }
    }
  }

  &.small {
    .mat-mdc-text-field-wrapper {
      padding: 0 12px;

      .mat-mdc-form-field-infix {
        --mat-form-field-container-height: 30px;
        --mat-form-field-container-vertical-padding: 3px;
        width: auto;

        .mat-mdc-select {
          .mat-mdc-select-trigger {
            gap: 8px;

            .mat-mdc-select-arrow-wrapper {
              margin: 0 4px 4px 0;
              --mat-select-enabled-arrow-color: transparent;
              --mat-select-focused-arrow-color: transparent;
            }
          }

          .mat-mdc-select-min-line {
            font: #{fonts.$body-small};
            --mat-select-placeholder-text-color: #{colors.$text-color-default};
          }
        }
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    border-radius: sizes.$border-radius-200;
    border: 1px solid colors.$button-border-color-gray;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
    --mdc-filled-text-field-active-indicator-height: 0;
    --mdc-filled-text-field-container-color: #{colors.$color-white};
    --mdc-filled-text-field-error-active-indicator-color: #{colors.$button-border-color-gray};
    --mdc-filled-text-field-input-text-color: #{colors.$text-color-default};
    --mdc-filled-text-field-disabled-container-color: #{colors.$button-border-color-gray};

    &.mdc-text-field--disabled {
      border: 1px solid colors.$checkbox-disabled-border-color;
      --mdc-filled-text-field-disabled-input-text-color: #{colors.$button-basic-disabled-label-text};
    }

    &.mdc-text-field--invalid {
      border-color: colors.$border-danger-default;
    }

    .mat-mdc-form-field-infix {
      --mat-form-field-container-vertical-padding: 8px;
      --mat-form-field-container-height: 40px;
      --mdc-filled-text-field-disabled-input-text-color: #{colors.$button-basic-disabled-label-text};
      --mat-form-field-select-option-text-color: #{colors.$text-color-default};
    }

    //TODO change placeholder color
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
      --mdc-filled-text-field-input-text-color: #{colors.$button-basic-disabled-label-text};
    }

    .cdk-textarea-autosize {
      min-height: 56px !important;
    }

    &:has(select) {
      padding: 0;

      .mat-mdc-form-field-input-control {
        padding: 0 29px 0 15px
      }

      .mat-mdc-form-field-infix::after {
        right: 15px;
      }
    }
  }

  .mat-mdc-form-field-error-wrapper {
    --mat-form-field-error-text-color: #{colors.$border-danger-default};
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 16px;
  }

}

.mat-mdc-slider {

  .mdc-slider__track {
    --mdc-slider-inactive-track-height: 6px;
    --mdc-slider-active-track-height: 6px;

    .mdc-slider__track--inactive {
      opacity: 1;
      --mdc-slider-inactive-track-color: #{colors.$bgr-brand-secondary};
      --mdc-slider-disabled-inactive-track-color: #{colors.$bgr-brand-secondary};
    }

    .mdc-slider__track--active {
      opacity: 1;
      --mdc-slider-active-track-color: #{colors.$primary};
      --mdc-slider-disabled-active-track-color: #{colors.$primary};
    }
  }


  &.mdc-slider.mdc-slider--disabled {
    opacity: 1;

    .mdc-slider__thumb-knob {
      border: 1px solid colors.$button-basic-disabled-label-text;
    }
  }

  .mdc-slider__thumb {
    .mdc-slider__thumb-knob {
      --mdc-slider-handle-width: 16px;
      --mdc-slider-handle-height: 16px;
      --mdc-slider-disabled-handle-color: #{colors.$button-border-color-gray};
      --mdc-slider-handle-color: #{colors.$primary};
      --mdc-slider-handle-elevation: none;
      --mdc-slider-focus-handle-color: #{colors.$primary};
      --mdc-slider-hover-handle-color: #{colors.$primary};
    }

    .mat-ripple {
      display: none;
      height: 28px;
      width: 28px;
      margin: auto;
      border-radius: sizes.$border-radius-full;

      .mat-mdc-slider-focus-ripple, .mat-mdc-slider-active-ripple {
        --mat-slider-focus-state-layer-color: #{colors.$mat-ripple-light-black};
      }
    }
  }

  &.mdc-slider {
    margin: 0;
    width: 100%;
    height: 16px;

    .mdc-slider__input {
      height: 12px;
    }

    .mdc-slider__thumb {
      height: 16px;
    }
  }

}

.mat-mdc-select-panel:has(.mat-mdc-option) {
  padding: 0;
  min-width: 130px;

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    width: 10px;
    height: 5px;
  }
}

.mat-mdc-select {
  --mat-select-enabled-trigger-text-color: #{colors.$text-color-default};
  --mat-select-trigger-text-font: #{fonts.$single-line-body-base};

  font: #{fonts.$single-line-body-base};

  .mat-mdc-select-min-line {
    font: #{fonts.$single-line-body-base};
    --mat-select-trigger-text-font: #{fonts.$single-line-body-base};
  }
}

.mat-mdc-option-active.mdc-list-item {
  --mat-option-focus-state-layer-color: #{colors.$color-white};

  &:hover {
    --mat-option-focus-state-layer-color: #{colors.$bgr-brand-secondary};
  }
}

.mat-form-field-appearance-fill .mdc-text-field--no-label .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  border-bottom: 2px solid colors.$text-color-default;
  border-right: 2px solid colors.$text-color-default;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
}

.mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 8px;
  --mat-form-field-container-text-font: fonts.$font-family-primary;
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    --mat-option-label-text-color: #{colors.$text-color-default};
    --mat-option-hover-state-layer-color: #{colors.$bgr-brand-secondary};
    --mat-option-label-text-font: #{fonts.$single-line-body-base};

    .mdc-list-item__primary-text {
      font: fonts.$single-line-body-base;
    }

    &.mdc-list-item {
      background: colors.$color-white;
    }
  }
}

.cdk-overlay-container:has(.reloyalty-blurred-dialog) .cdk-overlay-dark-backdrop {
  background-color: #fff8;
  backdrop-filter: blur(2px);
}

.cdk-overlay-pane {
  &.reloyalty-dialog {
    --mdc-dialog-container-color: #{colors.$color-white};
    --mdc-dialog-supporting-text-color: #{map-get(colors.$neutral-palette, 30)};

    .mat-mdc-dialog-container .mat-mdc-dialog-content {
      --mat-dialog-content-padding: 32px;
    }

    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: #{sizes.$border-radius-200};
    }
  }

  &.reloyalty-blurred-dialog {
    --mdc-dialog-container-color: #{colors.$color-white};
    --mdc-dialog-supporting-text-color: #{colors.$text-color-default};

    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        --mdc-dialog-container-shape: #{sizes.$border-radius-200};

        box-shadow: shadows.$drop-shadow-2000;
        overflow: visible;
      }

      .mat-mdc-dialog-content {
        --mat-dialog-content-padding: 32px;

        max-height: 90dvh;
        border: 1px solid colors.$button-border-color-gray;
        box-sizing: border-box;
        border-radius: sizes.$border-radius-200;
        box-shadow: shadows.$drop-shadow-500;
      }
    }
  }
}

.mat-divider {
  --mat-divider-color: #00000010;
}

.mat-mdc-tab-group {
  --mat-tab-header-inactive-label-text-color: #{colors.$actions-menu-item-color};
  --mat-tab-header-inactive-focus-label-text-color: #{colors.$actions-menu-item-color};
  --mat-tab-header-inactive-hover-label-text-color: #{colors.$actions-menu-item-color};
  --mat-tab-header-active-focus-label-text-color: #{colors.$actions-active-menu-item-border};
  --mat-tab-header-active-label-text-color: #{colors.$actions-active-menu-item-border};
  --mat-tab-header-active-hover-label-text-color: #{colors.$actions-active-menu-item-border};
  --mat-tab-header-divider-color: #{colors.$color-white};

  .mat-mdc-tab-header {
    margin-bottom: 20px;
    width: fit-content;
  }

  .mat-mdc-tab-labels {
    --mat-tab-header-label-text-size: #{sizes.$size-4};
    --mat-tab-header-label-text-weight: 400;
    --mat-tab-header-label-text-line-height: 22.4px;
    --mat-tab-header-label-text-font: #{fonts.$font-family-primary};

    .mdc-tab {
      min-width: unset;
      padding: 0 12px;

      .mat-mdc-tab-ripple {
        border-radius: sizes.$border-radius-100 sizes.$border-radius-100 0 0;
      }

      .mdc-tab__ripple::before {
        border-radius: sizes.$border-radius-100 sizes.$border-radius-100 0 0;
      }
    }

    .mat-mdc-tab {
      --mdc-secondary-navigation-tab-container-height: 30px;
    }

    .mdc-tab__text-label {
      line-height: 22.4px;
    }
  }

  .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
    --mat-tab-header-active-focus-indicator-color: #{colors.$actions-menu-item-color};
    --mdc-tab-indicator-active-indicator-color: #{colors.$actions-menu-item-color};
    --mat-tab-header-active-hover-indicator-color: #{colors.$actions-menu-item-color};

  }
}

.mat-mdc-tab-nav-bar {
  .mat-mdc-tab-link-container {
    --mat-tab-header-divider-color: transparent;

    .mat-mdc-tab-link.selected .mdc-tab-indicator__content {
      transition: all 250ms;
      --mdc-tab-indicator-active-indicator-color: #{colors.$actions-active-menu-item-border};
      --mdc-tab-indicator-active-indicator-height: 1px;
      opacity: 1;
    }
  }
}

.mat-mdc-input-element.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font: fonts.$body-base;
  line-height: 23.4px;
}

.mat-mdc-option {
  --mat-option-label-text-font: #{fonts.$font-family-primary};
  --mat-option-label-text-size: #{sizes.$size-4};
  --mat-option-label-text-weight: 400;
}

div.mat-mdc-autocomplete-panel.mdc-menu-surface--open {
  padding: 0;
}

.mat-datepicker-content {
  --mat-datepicker-calendar-container-shape: #{sizes.$border-radius-150};
  --mat-datepicker-calendar-container-elevation-shadow: #{shadows.$drop-shadow-100};
  --mat-datepicker-calendar-period-button-text-size: 14px;

  .mat-calendar-header {
    padding: 3px 3px 0 3px;
  }

  .mat-calendar-controls {
    margin: 4px 0 8px 0;
  }

  .mat-calendar-table-header {
    font: #{fonts.$sidebar-menu-item};
    font-weight: 400;

    th:not(.mat-calendar-table-header-divider) {
      padding-bottom: 14px;
    }
  }

  .mat-calendar-body-label {
    visibility: hidden;

    &:only-child {
      display: none;
    }
  }

  .mat-calendar-body-cell-content {
    --mat-datepicker-calendar-date-focus-state-background-color: #fff;
    --mat-datepicker-calendar-date-selected-state-background-color: #{colors.$primary};
    --mat-datepicker-calendar-date-today-outline-color: #{colors.$primary};
    --mat-datepicker-calendar-text-size: 16px;
    --mat-datepicker-calendar-text-font: #{fonts.$font-family-roboto};
    --mat-datepicker-calendar-date-text-color: #1D1B20;
  }

  .mat-calendar-table-header-divider {
    padding-bottom: 0;
  }


  --mdc-text-button-label-text-color: #{colors.$calendar-subtitle-color};
  --mat-datepicker-calendar-period-button-text-color: #{colors.$calendar-subtitle-color};
  --mat-datepicker-calendar-period-button-icon-color: #{colors.$calendar-subtitle-color};
  --mat-datepicker-calendar-navigation-button-icon-color: #{colors.$calendar-subtitle-color};
  --mat-datepicker-calendar-header-text-color: #{colors.$calendar-subtitle-color};

}

.mat-mdc-select-arrow-wrapper {
  .mat-mdc-select-arrow {
    --mat-select-enabled-arrow-color: transparent;
    --mat-select-focused-arrow-color: transparent;
    --mat-select-invalid-arrow-color: transparent;
  }

  mat-icon {
    width: 16px;
  }
}

::placeholder {
  color: colors.$button-basic-disabled-label-text !important;
  opacity: 1;
}

.mat-bottom-sheet-container {
  --mat-bottom-sheet-container-background-color: #{colors.$color-white};
  --mat-bottom-sheet-container-text-line-height: 14px;

  border-radius: sizes.$border-radius-750 sizes.$border-radius-750 0 0;

  &.cdk-dialog-container {
    padding: 0;
    box-shadow: none;
  }
}

.multiselect-chip-grid {
  .mat-mdc-chip-action {
    --mdc-chip-outline-color: transparent;
  }
}

.input-autocomplete {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    .mdc-text-field {
      background-color: colors.$input-disabled-background-color;
      border-color: colors.$input-disabled-border-color;
      color: colors.$input-disabled-text-color;
    }
  }
}

// The following class styles are used in the autocomplete multiselect component in the back office. The reason for them
// is that the default styles squish the mat-checkbox to it's smallest size. This leads to the multiselect not working
// properly when clicking outside the label. The following styles fix this issue by making the label take all the free
// space in the list item.
.autocomplete-checkbox-option {
  display: flex;
  flex-direction: column;
   .mdc-list-item__primary-text {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     flex: auto;
     .mat-mdc-checkbox {
       height: 100%;
       width: 100%;
       display: flex;
       flex-direction: column;
       flex: auto;
       .mdc-form-field {
         height: 100%;
         width: 100%;
         display: flex;
         flex-direction: row;
         align-items: center;
         flex: auto;
         .mdc-label {
           height: 100%;
           width: 100%;
           display: flex;
           align-items: center;
           min-height: 48px;
         }
       }
     }
   }


  .indeterminate  {
    .mdc-checkbox__background {
      background: map-get(colors.$primary-palette, 50) !important;
      border-color: map-get(colors.$primary-palette, 50) !important;
    }
  }
  .mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background: map-get(colors.$primary-palette, 50) !important;
      border-color: map-get(colors.$primary-palette, 50) !important;
    }
  }
 }
