@mixin flex($direction, $justify: flex-start, $align: flex-start, $wrap: nowrap) {
  @if $justify == start or $justify == end {
    $justify: flex- + $justify;
  }
  @if $align == start or $align == end {
    $align: flex- + $align;
  }
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  box-sizing: border-box;
  align-content: $align;
  align-items: $align;
  justify-content: $justify;
}

@mixin column($justify: flex-start, $align: flex-start, $wrap: nowrap) {
  @include flex(column, $justify, $align, $wrap)
}

@mixin row($justify: flex-start, $align: flex-start, $wrap: nowrap) {
  @include flex(row, $justify, $align, $wrap)
}


.flex {
  display: flex;
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;

  &-row {
    @extend .flex;
    flex-direction: row;
  }

  &-row-reverse {
    @extend .flex;
    flex-direction: row-reverse;
  }

  &-column {
    @extend .flex;
    flex-direction: column;
  }

  &-column-reverse {
    @extend .flex;
    flex-direction: column;
  }

  &.justify {
    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-center {
      justify-content: center;
    }

    &-stretch {
      justify-content: stretch;
    }

    &-space-around {
      justify-content: space-around;
    }

    &-space-between {
      justify-content: space-between;
    }

    &-space-evenly {
      justify-content: space-evenly;
    }
  }

  &.align {
    &-start {
      align-items: flex-start;
      align-content: flex-start;
    }

    &-end {
      align-items: flex-end;
      align-content: flex-end;
    }

    &-center {
      align-items: center;
      align-content: center;
    }

    &-stretch {
      align-items: stretch;
      align-content: stretch;
    }

    &-space-around {
      align-content: space-around;
    }

    &-space-between {
      align-content: space-between;
    }

    &-space-evenly {
      align-content: space-evenly;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.align-self {
  &-start {
    align-self: flex-start;
  }

  &-end {
    align-self: flex-end;
  }

  &-center {
    align-self: center;
  }

  &-stretch {
    align-self: stretch;
  }
}
