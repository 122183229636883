@use "sass:map" as map;

$primary-palette: (
        0: #000000,
        10: #001EBF,
        20: #0036D0,
        25: #0142DB,
        30: #1A4DE8,
        35: #2156F4,
        40: #5171F8,
        50: #768DFA,
        60: #A1ADFB,
        70: #C7CDFC,
        80: #E9EBFE,
        90: #F1F4FF,
        95: #F6F7FF,
        98: #FBFCFF,
        99: #FEFEFF,
        100: #ffffff
);

$secondary-palette: (
        0: #000000,
        10: #006238,
        20: #008055,
        25: #009063,
        30: #00A173,
        35: #00AF81,
        40: #00BE92,
        50: #00CBA6,
        60: #70DBC0,
        70: #ACE9D9,
        80: #DDF6F0,
        90: #E6F9F4,
        95: #F0FCF8,
        98: #F7FDFB,
        99: #FBFEFD,
        100: #ffffff
);

$tertiary-palette: (
        0: #000000,
        10: #010101,
        20: #020202,
        25: #050505,
        30: #0A0A0A,
        35: #171717,
        40: #262626,
        50: #404040,
        60: #525252,
        70: #737373,
        80: #A3A3A3,
        90: #D4D4D4,
        95: #E5E5E5,
        98: #F5F5F5,
        99: #FAFAFA,
        100: #ffffff
);

$neutral-variant-palette: (
        0: #ffffff,
        10: #FAFAFA,
        20: #F5F5F5,
        25: #E5E5E5,
        30: #D4D4D4,
        35: #A3A3A3,
        40: #737373,
        50: #525252,
        60: #404040,
        70: #262626,
        80: #171717,
        90: #0A0A0A,
        95: #050505,
        98: #020202,
        99: #010101,
        100: #000000
);

$neutral-palette: (
        0: #000000,
        10: #010101,
        20: #020202,
        25: #050505,
        30: #0A0A0A,
        35: #171717,
        40: #262626,
        50: #404040,
        60: #525252,
        70: #737373,
        80: #A3A3A3,
        90: #D4D4D4,
        95: #E5E5E5,
        98: #F5F5F5,
        99: #FAFAFA,
        100: #ffffff
);

$error-palette: (
        0: #000000,
        10: #450A0A,
        20: #7F1D1D,
        25: #991B1B,
        30: #B91C1C,
        35: #DC2626,
        40: #EF4444,
        50: #F87171,
        60: #FCA5A5,
        70: #FECACA,
        80: #FEE2E2,
        90: #FEF2F2,
        95: #FFF7F7,
        98: #FFFBFB,
        99: #FFFDFD,
        100: #FFFFFF
);

$error-secondary-palette: (
        0: #000000,
        10: #431407,
        20: #7C2D12,
        25: #9A3412,
        30: #C2410C,
        35: #EA580C,
        40: #F97316,
        50: #FB923C,
        60: #FDBA74,
        70: #FED7AA,
        80: #FFEDD5,
        90: #FFF7ED,
        95: #FFF7ED,
        98: #FFFFFF,
        100: #FFFFFF
);

$loyalty-primary-palette: (
        0: #000000,
        10: #4623A3,
        20: #5930B0,
        25: #6436B8,
        30: #723EC2,
        35: #7C44C8,
        40: #8F60D1,
        50: #A37EDA,
        60: #BDA4E4,
        70: #D7C8EF,
        80: #f0e9f8,
        90: #F6F0FB,
        95: #FBF7FD,
        98: #FEFBFF,
        99: #FFFDFF,
        100: #ffffff
);


$primary: map.get($primary-palette, 35); //2156F4

$success: map.get($secondary-palette, 50); //00CBA6
$error: map.get($error-palette, 35); //DC2626

$text-color-default: #1E1E1E;
$color-white: #ffffff;
$color-black: #000000;

//Loyalty Level Colors
$map-color-loyalty-level-devoted: #6A2AFF;
$map-color-loyalty-level-loyal: #4EAC68;
$map-color-loyalty-level-frequent: #4EAC68;
$map-color-loyalty-level-casual: #FA966F;
$map-color-loyalty-level-stranger: #D7D7D7;

// Inputs
$input-disabled-background-color: #D9D9D9;
$input-disabled-border-color: #B2B2B2;
$input-disabled-text-color: #B3B3B3;

//Buttons
$button-basic-disabled-label-text: #B3B3B3;
$button-border-color-gray: #D9D9D9;
$button-primary-small-bgr: #ACB7FC;

//Form fields
$checkbox-border-color-default: #757575;
$checkbox-disabled-border-color: #B2B2B2;
$bgr-black-light: #2C2C2C;
$border-danger-default: #900B09;
$bgr-brand-secondary: #E6E6E6;
$mat-ripple-light-black: #2C2C2C33;
$shadow-black-100: #0C0C0D0D;

//actions page
$actions-active-menu-item-border: #303030;
$actions-menu-item-color: #767676;
$action-active-state-color: #15803D;
$action-active-state-background: #F0FDF4;

$dropdown-item-hover: #f3f4f5;
$calendar-subtitle-color: #49454F;
$subtitle-text-color: #4A4A4A;

//mobile app
$features-item-bgr: #EEEEEE;
$features-item-title: #0F0101;
$features-item-subtitle: #868585;
$features-item-image-bgr: #120101;
$navigation-border-color: #CBCBD433;
$nav-item-color: #676767;
$nav-item-active-color: #333333;
$value-rating-bgr-color: #454545;

//loyalty mobile app
$light-pink-background-color: #FFECE4;
$light-gray-background-color: #E4E4E4;
$red-background-color: #D93838;
$black-background-color: #121212;
$black-text-color: #010000;
$black-text-lighter-color: #1B1B1B;
$counter-white-background-color: #FFFFFF33;
$green-background-color: #D1F4DA;
$orange-color: #F6C964;
$orange-light-color: #FDE0A8;
$purple-pill-color: #E1D3FF;
$peach-pill-color: #FFDDD0;
$brown-light-color: #C29B48;
$brown-text-color: #594317;
$brown-lightest-color: #FFEDC7;
$gray-panel-background-color: #F7F7F7;
$gray-value-color: #7B7B7B;
