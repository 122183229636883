$size-0: 0;
$size-px: 1px;
$size-0-5: 2px;
$size-1: 4px;
$size-1-5: 6px;
$size-2: 8px;
$size-2-5: 10px;
$size-3: 12px;
$size-3-5: 14px;
$size-4: 16px;
$size-5: 20px;
$size-6: 24px;
$size-7: 28px;
$size-8: 32px;
$size-9: 36px;
$size-10: 40px;
$size-11: 44px;
$size-12: 48px;
$size-14: 56px;
$size-16: 64px;
$size-20: 80px;
$size-24: 96px;
$size-28: 112px;
$size-32: 128px;
$size-36: 144px;

$border-radius-100: 4px;
$border-radius-150: 6px;
$border-radius-200: 8px;
$border-radius-250: 10px;
$border-radius-275: 10px;
$border-radius-300: 12px;
$border-radius-500: 20px;
$border-radius-750: 30px;
$border-radius-full: 9999px;
